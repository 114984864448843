export const GQL_FETCH_VALDHUB_PAGE_BANNER_TEXT_ENTRY = `
  heroBannerText: pageComponentsCollection(where: {sys: {id: "5R8G2IQ0zLTw3X6JigEnAi"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        logo {
          url
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_BANNER_ASSETS_ENTRY = `
  heroBannerAssets: pageComponentsCollection(where: {sys: {id: "56qpZB0QEbLlpksoHInNiD"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        mediaPrimary {
          url
          width
          height
          description
        }
        extraAssetsCollection {
          items {
            ... on Image {
              sys {
                id
              }
              title
              media {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_CENTRALIZED_ENTRY = `
  centralized: pageComponentsCollection(where: {sys: {id: "3RsmGSng215HaIqcbylscq"}}, limit: 1) {
    items {
      ... on SectionHeader {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_FULLY_FEATURED_ENTRY = `
  fullFeatured: pageComponentsCollection(where: {sys: {id: "75MHVyZwVLiAS7ykdRKa0x"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_ACCURATE_ENTRY = `
  accurate: pageComponentsCollection(where: {sys: {id: "7kHM8df8fqYZFJJfdH3PUS"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_CUSTOMIZABLE_ENTRY = `
  customizable: pageComponentsCollection(where: {sys: {id: "3JlcpS1L8HZCdQLdwfPX9Y"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_SHARING_CARING_ENTRY = `
  sharingCaring: pageComponentsCollection(where: {sys: {id: "2H43ezDmoMGA273EQUOUwV"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_NORMATIVE_DATA_ENTRY = `
  normativeData: pageComponentsCollection(where: {sys: {id: "5EGVLnK8exq91xXEbhMrKm"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          description
          width
          height
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
        extraAssetsCollection {
          items {
            ... on Json {
              json
              bodyCopy {
                json
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_CONTROLS_ENTRY = `
  controls: pageComponentsCollection(where: {sys: {id: "3znvIaPvsJ9wUdy8oDzfYk"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_INTEGRATIONS_ENTRY = `
  integrations: pageComponentsCollection(where: {sys: {id: "1V2SeFaH7YhPNzUKtId5dM"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection {
          items {
            text
            url
            type
            target
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_RELATED_SYSTEMS_ENTRY = `
  systems: pageComponentsCollection(where: {sys: {id: "37m95W2gGHD4t7v9UkViao"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_VALDHUB_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "7iD8cI1SQzBPhNQtGKWMqN" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
