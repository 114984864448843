export const GQL_FETCH_FORCEFRAME_PAGE_BANNER_ENTRY = `
   forceFrameHeroBanner: pageComponentsCollection(where: { sys: { id: "7FnoAPY1yXImBoioFf04Sp" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_FORCEFRAME_PAGE_FLEXIBILITY_ENTRY = `
    textVideoFlexibility: pageComponentsCollection(
      where: {sys: {id: "CndZOhkIaye9Pf1nWBG7N"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FORCEFRAME_PAGE_MODULARITY_ENTRY = `
    textImageModularity: pageComponentsCollection(
      where: {sys: {id: "J0NiKtZ5dS6PrdgeWTDDX"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 3) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FORCEFRAME_PAGE_FLEXIBILITY_PATIENTS_ENTRY = `
    textVideoFlexibilityPatient: pageComponentsCollection(
      where: {sys: {id: "2KlTu5EkbGGnpQiTozBEd4"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
          }
        }
      }
    }
`;

export const GQL_FETCH_FORCEFRAME_PAGE_TEST_AND_TRAIN_ENTRY = `
    textImageTestAndTrain: pageComponentsCollection(
      where: {sys: {id: "7sSmwVwHKKjC2fSCHO8aZ"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 3) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FORCEFRAME_PAGE_FEATURE_IMAGE_ENTRY = `
  featureImage: pageComponentsCollection(where: { sys: { id: "6JMGxnEnRoec71eitgPVWN" } }, limit: 1) {
      items {
        ... on DuplexComponent {
        scrollTo
        preTitle
        title
          bodyCopy {
          json
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_FORCEFRAME_PAGE_MAX_CARDS_ENTRY = `
  forceframeMax: pageComponentsCollection( where: {sys: {id: "2Ggb9lGH1wAO8tRxSCLgOV"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        preTitle
        title
        scrollTo
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
      }
    }
  }    
`;

export const GQL_FETCH_FORCEFRAME_PAGE_FOLD_CARDS_ENTRY = `
  forceframeFold: pageComponentsCollection( where: {sys: {id: "6MtmsWcsVlMQQiLvAKUNvi"}}, limit: 1) {
    items {
      ... on DuplexComponent {
        title
        scrollTo
        bodyCopy {
          json
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
      }
    }
  }    
`;

export const GQL_FETCH_FORCEFRAME_PAGE_FAQ_ENTRY = `
faqFF: pageComponentsCollection(where: { sys: { id: "5dXVYbLg7T9A4VlNDCAWky" } }, limit: 1){
  items{
    ... on Grid{
      scrollTo
      preTitle
      title
      topicCollection{
        items{
          ... on Card{
            title
            bodyCopy{
              json
            }
          }
        }
      }
    }
  }
}
`;

export const GQL_FETCH_FORCEFRAME_PAGE_FLEXIBLE_PAYMENT_PLAN_ENTRY = `
textImagePaymentPlan: pageComponentsCollection(
  where: { sys: { id: "1QBPc7ILhTL7mts99e71bG" } }
      limit: 1
) {
      items {
        ... on DuplexComponent {
        scrollTo
      preTitle
      title
          bodyCopy {
        json
      }
          mediaPrimary {
        description
        url
        width
        height
      }
          mediaSecondary {
        description
        url
        width
        height
      }
          mediaTertiary {
        description
        url
        width
        height
      }
      buttonCollection(limit: 2) {
            items {
              sys {
            id
          }
          text
          url
          target
          type
        }
      }
    }
  }
}
`;

export const GQL_FETCH_FORCEFRAME_PAGE_RELATED_SYSTEMS_ENTRY = `
relatedFFSystems: pageComponentsCollection(
  where: { sys: { id: "5owC1fpYiImEaj5lfebkTl" } }
      limit: 1
) {
  items {
    ... on Grid {
      scrollTo
      preTitle
      title
      topicCollection {
        items {
          ... on Product {
            sys {
              id
            }
            mediaPrimary {
              description
              url
              width
              height
            }
            logo {
              url
              description
            }
            name
            title
            slug
            bodyCopy {
              json
            }
            button {
              text
              url
              type
              target
            }
          }
        }
      }
    }
  }
}
`;

export const GQL_FETCH_FORCEFRAME_PAGE_RELATED_PRODUCTS_ENTRY = `
products: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "6zBPG8aMkJGdtMS6K5afoB"}}}) {  
  items {
      sys {
      id
    }
      mediaPrimary {
      description
      url
      width
      height
    }
      logo {
      url
      description
    }
    name
    title
    slug
      bodyCopy {
      json
    }
      button {
      text
      url
      type
      target
    }
  }
}
`;

export const GQL_FETCH_FORCEFRAME_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "3WF7Sf20HehnCxQA5x49f7" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
