'use client';

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Modal from '../Modal';
import NextImage from '../NextImage';
import setCookie from '@src/utils/setCookie';
import getCookie from '@src/utils/getCookie';
import isBot from '@src/utils/isBot';
import { CountriesInfoPropTypes } from './GeoPopup.types';
const GeoPopup = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const userTimeZone = dayjs.tz.guess();
  const userCountyTz = userTimeZone?.split('/')[1];

  // Load the timezone data
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const timezoneData = require('./timezone-data.json');
  const {
    countries,
    zones
  } = timezoneData;

  // Define the type for timeZoneToCountry
  const timeZoneToCountry: {
    [key: string]: string;
  } = {};
  Object.keys(zones).forEach(z => {
    const cityArr = z.split('/');
    const city = cityArr[cityArr.length - 1];
    timeZoneToCountry[city] = countries[zones[z].countries[0]].abbr;
  });
  const countryCode = timeZoneToCountry[userCountyTz] ? timeZoneToCountry[userCountyTz] : '';
  const countriesInfo: CountriesInfoPropTypes = {
    DE: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/1Aog3BbygVHM0BBnPwr9v2/117accf1cdd9c877467e0e6fda53b499/btm_popup_germany_v2.png',
      introbold: 'Nimm Kontakt',
      intro: ' zu einem unserer deutschen Mitarbeiter auf',
      bdms: [{
        name: 'Vincent Simons',
        email: 'v.simons@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:v.simons@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        name: 'John Boyer',
        email: 'j.boyer@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:j.boyer@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        name: 'Andrew Quarmby',
        email: 'a.quarmby@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:a.quarmby@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    JP: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/48fXdg9pfOxrNNzIkCS2Hr/4c8e930c6a8ea8de06f7348e4a43e710/VP_Website-Popup_Japan.png',
      introbold: '',
      intro: ' 日本語でのお問い合わせはこちらまで',
      bdms: [{
        name: 'Motoki Miyamoto',
        email: 'm.miyamoto@vald.com',
        contactbutton: 'お問い合わせ',
        buttonlinkaddress: 'mailto:m.miyamoto@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    IT: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5epDiRDmrpKsruPmiWazKx/401fdee1af7c7c2753367963db1313e7/Website-Popup_Isolated_Italy.png',
      introbold: 'Mettiti in contatto',
      intro: ' con uno dei nostri membri in Italia',
      bdms: [{
        name: 'Marco Cuniberti',
        email: 'm.cuniberti@vald.com',
        contactbutton: 'Contatto',
        buttonlinkaddress: 'mailto:m.cuniberti@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        name: 'Mario Ferrante',
        email: 'm.ferrante@vald.com',
        contactbutton: 'Contatto',
        buttonlinkaddress: 'mailto:m.ferrante@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    FR: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/2KEyUp7ZYX13owJyIrPdOi/99cf98b215ac48cc6688d32d2ab95355/VP_Website-Popup_France.png',
      introbold: 'Entrez en contact',
      intro: ' avec lun des membres de notre équipe en France',
      bdms: [{
        name: 'Laurent Antkowiak',
        email: 'l.antkowiak@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:l.antkowiak@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        name: 'Morgan Monge',
        email: 'm.monge@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:m.monge@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    MX: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/hIL5ZlGQ4uaq8f1oFOHX7/5ad9c9697ef7a60fb371b9e65d8c5a8c/VP_Website-Popup_Mexico.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Mexico.',
      bdms: [{
        name: 'Renato Trevino',
        email: 'r.trevino@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:r.trevino@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        name: 'Martin Lara',
        email: 'm.lara@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:m.lara@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    AR: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5VX4oZygMBXv1eWE910aY1/6b4ecfed771b45b3a7bd689d96529d05/VP_Website-Popup_Argentina.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Argentina.',
      bdms: [{
        name: 'Eduardo Tondelli',
        email: 'e.Tondelli@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:e.tondelli@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    CL: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/6R5mK9xMsatWYAnRIkilba/a7a161d1c2231337add81a6b4aeebcd2/VP_Website-Popup_Chile.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Chile.',
      bdms: [{
        name: 'Enzo Cacciuttolo',
        email: 'e.cacciuttolo@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:e.cacciuttolo@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    ES: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/3vGhVJsRCfwVkqdNBrRm67/4b281504b9304ba117a1bbdb710d3487/VP_Website-Popup_Spanish-3.png',
      introbold: 'Contacta un',
      intro: ' miembro de nuestro equipo en España',
      bdms: [{
        name: 'Pedro Castellon',
        email: 'p.castellon@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:p.castellon@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        name: 'Diego Soliño',
        email: 'd.solino@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:d.solino@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        name: 'Pau Martinez',
        email: 'p.martinez@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:p.martinez@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    CA: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/7guXW0gvVUvoMJc2Bi843D/092ae52d628c8533e009d3787080741d/VP_Website-Popup_Isolated_Canada.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Canada',
      bdms: [{
        name: 'Jason McCullagh',
        email: 'j.mccullagh@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:j.mccullagh@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    NO: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/1FnxLmWctXYMem3L3S8OY6/3a089bcf75b9c5f143b5d605031210c8/VP_Website-Popup_Isolated_Norway.png',
      introbold: 'Kom i kontakt',
      intro: ' med et av våre teammedlemmer i Norge',
      bdms: [{
        name: 'Simen Thøgersen',
        email: 's.thogersen@vald.com',
        contactbutton: 'Kontakt',
        buttonlinkaddress: 'mailto:s.thogersen@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    SI: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/25SwI0cJ8GAXPF6mxVcZW9/53dda14e6ecc2b8afc85d6869fe4bfbd/VP_Website-Popup_Isolated_Slovenia.png',
      introbold: 'Pošljite sporočilo',
      intro: ' članu naše ekipe v Sloveniji',
      bdms: [{
        name: 'Sebastjan Potokar',
        email: 's.potokar@vald.com',
        contactbutton: 'Pošlji sporočilo',
        buttonlinkaddress: 'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    PT: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/19VG7pikOmjGFMTYmnuUb5/a55912151220b71417031d2fa1e94c75/VP_Website-Popup_Isolated_Portugal.png',
      introbold: 'Entre em contacto',
      intro: ' com um dos membros da nossa equipa em Portugal',
      bdms: [{
        name: 'Diego Soliño',
        email: 'd.solino@vald.com',
        contactbutton: 'Contacto',
        buttonlinkaddress: 'mailto:d.solino@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    EG: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/1c8jWzMkmviNop70ClAZxZ/157bdfbd5b9d49b3deb7ef4fd0219673/VP_Website-Popup_Isolated_Egypt.png',
      introbold: 'تواصل',
      intro: ' مع أحد أعضاء فريقنا في مصر',
      bdms: [{
        name: 'Antonios Sayed',
        email: 'a.sayed@vald.com',
        contactbutton: 'اتصل',
        buttonlinkaddress: 'mailto:a.sayed@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    NZ: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/67Fy3TPVHclMFpl3C2kRlp/d97be757828b9c92506da6ab522a196a/VP_Website-Popup_Isolated-NZ.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in New Zealand',
      bdms: [{
        name: 'Kim Wilson',
        email: 'k.wilson@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:k.wilson@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    IE: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/3aAxLpCe4wXe0Pmijs7KX9/c96ff6bbc3aae0a6054b5d8b46394389/VP_Website-Popup_Isolated_Ireland.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Ireland',
      bdms: [{
        name: 'Conor Tierney',
        email: 'c.tierney@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:c.tierney@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    PL: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/1X3ukVuYvvRC2FYAGBSRon/66b59e0738174fa9305211472db1ad43/VP_Website-Popup_Isolated_Polish.png',
      introbold: 'Skontaktuj się z',
      intro: ' jednym z naszych członków zespołu w Polsce',
      bdms: [{
        name: 'Grzegorz Wojdala',
        email: 'g.wojdala@vald.com',
        contactbutton: 'Kontakt',
        buttonlinkaddress: 'mailto:g.wojdala@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    DK: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/3730WuIo7iZUGrUDHwK10T/63c18454786ed26d024f875fff1ceb02/VP_Website-Popup_Isolated_Danish.png',
      introbold: 'Kom i kontakt',
      intro: ' med et af vores teammedlemmer i Danmark',
      bdms: [{
        name: 'Simen Thøgersen',
        email: 's.thogersen@vald.com',
        contactbutton: 'Kontakt',
        buttonlinkaddress: 'mailto:s.thogersen@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    SA: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4SJlp7LJ9ACzmyMF67T0DH/9aa8fdd3ce6c07b84124f6f4d471f965/btm_popup_saudi_v1.png',
      introbold: '',
      intro: 'تواصل مع أحد أعضاء فريقنا في المملكة العربية السعودية',
      bdms: [{
        name: 'Antonios Sayed',
        email: 'a.sayed@vald.com',
        contactbutton: 'راجع',
        buttonlinkaddress: 'mailto:a.sayed@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    SE: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/6eG0cJBV7jxnPW7Vbyw6PG/9787307f88600aaf41ca1d7625c7a49b/Popup_sweden.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Sweden',
      bdms: [{
        name: 'Oda Lindahl',
        email: 'o.lindahl@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:o.lindahl@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    RO: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4anshToIgqMyaElQyVG7aF/2cf3501ce6210db758cefd0b49a588a6/Website-Popup_Isolated_Romania.png',
      introbold: 'Contactaţi-ne',
      intro: ' cu unul dintre membrii echipei noastre din România',
      bdms: [{
        name: 'Brandon Garcia',
        email: 'b.garcia@vald.com',
        contactbutton: 'a lua legatura',
        buttonlinkaddress: 'mailto:b.garcia@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    TR: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/7yx7QJVDODWReoEXvNT6di/044a6d712d49292654da26e73036d2f2/Website-Popup_Isolated_Trukiye.png',
      introbold: 'Bize Ulaşın',
      intro: `Türkiye'deki ekip üyelerimizden biriyle`,
      bdms: [{
        name: 'Mert Sahan',
        email: 'm.sahan@vald.com',
        contactbutton: 'Temas etmek',
        buttonlinkaddress: 'mailto:m.sahan@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        name: 'Denizhan Isiksal',
        email: 'd.isiksal@vald.com',
        contactbutton: 'Temas etmek',
        buttonlinkaddress: 'mailto:d.isiksal@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    RS: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4CkmUUUC6e7jcuTaJT2Wah/4c0dcf01dadcfdf52dec5123ea10ea99/Website-Popup_Isolated_Serbia.png',
      introbold: 'Контактирајте нас',
      intro: `са једним од наших чланова тима у Србији`,
      bdms: [{
        name: 'Sebastjan Potokar',
        email: 's.potokar@vald.com',
        contactbutton: 'Контакт',
        buttonlinkaddress: 'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    HR: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/7usoN9nSR9HMylCKmhk7cY/2744b76dd474655bb0e14d7f208950e9/Website-Popup_Isolated_Croatia.png',
      introbold: 'Kontaktirajte nas',
      intro: `s jednim od članova našeg tima u Hrvatskoj`,
      bdms: [{
        name: 'Sebastjan Potokar',
        email: 's.potokar@vald.com',
        contactbutton: 'Kontakt',
        buttonlinkaddress: 'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    CH: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/50wblVQlBYYHgA3SOHjn92/a8d1f988e6eb8e2a8838f0116e22423d/CH.jpg',
      introbold: 'Get in touch',
      intro: `with one of our team members in Switzerland`,
      bdms: [{
        flag: 'https://images.ctfassets.net/98s79sqwuajy/y7N4q1aGGNabRdK0Xnm45/923070bfd7baf17f4171f25896e55a8f/DE.jpg',
        name: 'Vincent Simons',
        email: 'v.simons@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:v.simons@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        flag: 'https://images.ctfassets.net/98s79sqwuajy/3xy2st7GnWjnCIay3PQtD5/6b6da4986902d334a281b2406efafb43/FR.jpg',
        name: 'Laurent Antkowiak',
        email: 'l.antkowiak@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:l.antkowiak@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }, {
        flag: 'https://images.ctfassets.net/98s79sqwuajy/6AO3JCKaKZLq6MM1r6odlN/f040c437d5f80bae43ee4045705bd84a/IT.jpg',
        name: 'Marco Cuniberti',
        email: 'm.cuniberti@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:m.cuniberti@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    AT: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5rw1ePN2TKdpQxiVCHJiZO/708f87756106d3b19dc3d20aea0def69/Website-Popup_Isolated_Austria.jpg',
      introbold: 'Get in touch',
      intro: `with one of our team members in Austria`,
      bdms: [{
        name: 'Lukas Arenas',
        email: 'l.arenas@vald.com',
        contactbutton: 'Contact',
        buttonlinkaddress: 'mailto:l.arenas@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    UY: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/2pZxFIbRpbmZVgeTALGx73/279616a483cf737f74356087f8378aac/Website-Popup_Isolated_Uruguay.png',
      introbold: 'Contacta un',
      intro: ' miembro de nuestro equipo en Uruguay',
      bdms: [{
        name: 'Eduardo Tondelli',
        email: 'e.tondelli@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:e.tondelli@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    AD: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5dQhWVnR2OLopr1RYT1NAw/3ef189ac71daf89265c8fb07ed396974/Website-Popup_Isolated_anadorra.svg',
      introbold: 'Contacta un',
      intro: ' miembro de nuestro equipo en Andorra',
      bdms: [{
        name: 'Pau Martinez',
        email: 'p.martinez@vald.com',
        contactbutton: 'Contacta',
        buttonlinkaddress: 'mailto:p.martinez@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    },
    GR: {
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4m1xZYg3IFoS7GGeRO8sI/4b2c4512430cab8f75e99493733d13cd/website-popup-greece.png',
      introbold: 'Επικοινωνήστε',
      intro: ' Επικοινωνήστε με ένα από τα μέλη της ομάδας μας στην Ελλάδα',
      bdms: [{
        name: 'Giannis Stergianos',
        email: 'i.stergianos@vald.com',
        contactbutton: 'Επικοινωνήστε',
        buttonlinkaddress: 'mailto:i.stergianos@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com'
      }]
    }
  };
  const stringifiedCountryCode = String(countryCode);
  const popupCountry = countryCode ? countriesInfo[stringifiedCountryCode] : null;
  const [showModal, setShowModal] = useState(false);
  const modalInfo = popupCountry;
  const handleClose = () => {
    setShowModal(false);
    setCookie('geoPopupVisited', 'true', 7);
  };
  const addedGeoPopupCookie = getCookie('geoPopupVisited');
  useEffect(() => {
    setTimeout(() => {
      if (modalInfo !== undefined && modalInfo !== null && !addedGeoPopupCookie && isBot(window.navigator.userAgent) === false) {
        setShowModal(true);
      }
    }, 2000);
  }, [modalInfo, addedGeoPopupCookie]);
  return <div data-sentry-component="GeoPopup" data-sentry-source-file="GeoPopup.tsx">
      {showModal && <Modal className='!top-[18%] w-[calc(100%-2.4rem)] md:w-[44rem] md:px-[3.6rem]' onClose={() => handleClose()}>
          <div className='mx-auto'>
            {modalInfo?.countryFlag && <NextImage className='mx-auto max-w-[60%]' src={modalInfo?.countryFlag} alt='A portrait' objectFit='contain' />}

            <div className='mt-[2.5rem] w-full text-[1.6rem]'>
              <p className='mb-[2.4rem] text-[1.6rem]'>
                <span className='block font-bold' id='introbold'>
                  {modalInfo?.introbold}
                </span>
                <span id='intro'>{modalInfo?.intro}</span>
              </p>

              {modalInfo?.bdms?.map(item => {
            return <div className='flex items-center [&:not(:first-child)]:mt-[1.1rem]' key={item?.email}>
                    <div className='flex'>
                      {item?.flag && <NextImage className='mr-[1.1rem] h-[3.2rem] w-[4.8rem]' src={`${item?.flag}`} alt='Country Flag' objectFit='contain' />}
                      <div>
                        <p className='mb-[0.2rem]'>{item?.name}</p>
                        <a href={`mailto:${item?.email}?subject=Online enquiry from VALD Health&cc=info@vald.com`} className='bdm-popup-tracking-gtm'>
                          {item?.email?.toLowerCase()}
                        </a>
                      </div>
                    </div>

                    <a id='buttonlinkaddress' className='bdm-popup-tracking-gtm common-btn-transition ml-auto mt-[1rem] inline-flex items-center justify-center rounded-[0.6rem] border-primary bg-primary px-[1rem] py-[0.8rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] !text-[#fff] hover:border-primary--v2 hover:text-primary--v2 md:px-[2rem]' href={item?.buttonlinkaddress}>
                      {item?.contactbutton}
                    </a>
                  </div>;
          })}
            </div>
          </div>
        </Modal>}
    </div>;
};
export default GeoPopup;