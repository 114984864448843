export const GQL_FETCH_FORCEDECKS_PAGE_HERO_ENTRY = `
  forceDecksHeroBanner: pageComponentsCollection(where: { sys: { id: "3bMXCxPYIxVY1L6pZK4LYP" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_MADE_EASY_ENTRY = `
  madeEasy: pageComponentsCollection(where: { sys: { id: "7klKrkzrHMScjVEKrcP0em" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_USEABILITY_ENTRY = `
  useability: pageComponentsCollection(where: { sys: { id: "6ImCbkXVPndEwnqTFRry3T" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_VERSATILITY_ENTRY = `
  versatility: pageComponentsCollection(where: { sys: { id: "5jwYZlNwYYNVS7Td6yPeu" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
        extraAssetsCollection {
          items {
              ... on DuplexComponent {
                sys {
                  id
                }
                title
                mediaPrimary {
                description
                url
                width
                height
              }
            }
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_BALANCE_ASSESSMENT_ENTRY = `
  balanceAssessment: pageComponentsCollection(where: { sys: { id: "3u4Bd4X09L2rWUDhkOAVt0" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_VISION_ENTRY = `
  vision: pageComponentsCollection(where: { sys: { id: "4dMrPcIVcAmubnjwcSQWl2" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_FIT_FOR_PURPOSE_ENTRY = `
  fitForPurpose: pageComponentsCollection(where: { sys: { id: "7msr06vH1imLAjdTilbzBt" } }, limit: 1) {
      items {
        ... on DuplexComponent {
        scrollTo
        preTitle
        title
          bodyCopy {
          json
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
        mediaPrimary {
          url
          width
          height
          description
        }
        mediaSecondary {
          url
          width
          height
          description
        }
        mediaTertiary {
          url
          width
          height
          description
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
        extraAssetsCollection(limit: 3) {
          items {
            ... on DuplexComponent {
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                width
                height
                description
              }
              mediaSecondary {
                url
                width
                height
                description
              }
              buttonCollection(limit: 2) {
                items {
                  sys {
                    id
                  }
                  text
                  url
                  target
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_FD_VALIDATED_ENTRY = `
  validated: pageComponentsCollection(
      where: {sys: {id: "4jNazwTh5Imd9T1aeXz3VU"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_FLEXIBILITY_ENTRY = `
  flexibility: pageComponentsCollection(where: { sys: { id: "6TrQZDUvi0gdpK1WTEhzGJ" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_FORCEDECKS_RELATED_SYSTEMS_ENTRY = `
  relatedSystems: pageComponentsCollection(
    where: {sys: {id: "3AY8BPWAfVxIuQGjObgT71"}}
    limit: 1
  ) {
    items {
      ... on Grid {
        scrollTo
        preTitle
        title
        topicCollection {
          items {
            ... on Product {
              sys {
                id
              }
              mediaPrimary {
                description
                url
                width
                height
              }
              logo {
                url
                description
              }
              name
              title
              slug
              bodyCopy {
                json
              }
              button {
                text
                url
                type
                target
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_FORCEDECKS_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "3zIlos8AV6NpZQU4Fj5ZHe" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "5AFKmAzODtGJImYeSliaCg" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
