export const GQL_FETCH_NORDBORD_PAGE_BANNER_ENTRY = `
    nordbordHeroBanner: pageComponentsCollection(where: { sys: { id: "6KqmpzatFggQDugtIz83xz" } }, limit: 1) {
    items {
      ... on Banner {
        scrollTo
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_NORDBORD_PAGE_GOLD_STANDARD_ENTRY = `
    goldStandard: pageComponentsCollection(
      where: {sys: {id: "3HDSbz7CBSLfaR31ybxXvO"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_NORDBORD_PAGE_EASY_ENTRY = `
    speedAndEasy: pageComponentsCollection(
      where: {sys: {id: "4hFiLvcJPslJElf3stJNXi"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_NORDBORD_PAGE_TEST_ECCENTRIC_ENTRY = `
    testEccentric: pageComponentsCollection(
      where: {sys: {id: "58977xR7irW0c7EjsdEJGv"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
        }
      }
    }
`;

export const GQL_FETCH_NORDBORD_PAGE_USABILITY_ENTRY = `
    usability: pageComponentsCollection(
      where: {sys: {id: "AjZcZJfDfBbWxhNPS4x0e"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_NORDBORD_PAGE_FEATURES_ENTRY = `
  features: pageComponentsCollection(where: { sys: { id: "26G64XKaWYyFNGyG5AMTcM" } }, limit: 1) {
    items {
      ... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          description
          url
          width
          height
        }
        mediaSecondary {
          description
          url
          width
          height
        }
        buttonCollection(limit: 2) {
          items {
            sys {
              id
            }
            text
            url
            target
            type
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_NORDBORD_PAGE_FLEXIBILITY_ENTRY = `
    flexibility: pageComponentsCollection(
      where: {sys: {id: "5HMmbM6DD4cRE8uLe7mQpA"}}
      limit: 1
    ) {
      items {
        ... on DuplexComponent {
          scrollTo
          preTitle
          title
          bodyCopy {
            json
          }
          mediaPrimary {
            description
            url
            width
            height
          }
          buttonCollection(limit: 2) {
            items {
              sys {
                id
              }
              text
              url
              target
              type
            }
          }
        }
      }
    }
`;

export const GQL_FETCH_NORDBORD_PAGE_RELATED_SYSTEMS_ENTRY = `
  relatedSystems: pageComponentsCollection(
    where: {sys: {id: "1pqvZHg9SiIrbw24m5gfZJ"}}
    limit: 1
  ) {
    items {
      ... on Grid {
        scrollTo
        preTitle
        title
        topicCollection {
          items {
            ... on Product {
              sys {
                id
              }
              mediaPrimary {
                description
                url
                width
                height
              }
              logo {
                url
                description
              }
              name
              title
              slug
              bodyCopy {
                json
              }
              button {
                text
                url
                type
                target
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_NORDBORD_PAGE_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {categories: {sys: { id: "5MnIgenyil0KqQYo7l8MoT" }}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_NORDBORD_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "EHHm3HKM3dbic1OFUQQyz" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
